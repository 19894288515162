import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Innovation is about elevating human potential, and great things happen when best talents cross path with opportunity
Life at Kata
At Kata.ai, we are not only working hard, but we’re also working smart and having fun while doing it. We diverse background & perspective, pursue ideas that define the future through the power of technology, and create something that we’re proud of.`}</p>
    <p>{`Our office is home for remarkable people whether they are a dreamer, innovator, believer, doer, or an achiever. We help them to grow their current roles, encourage their potential both personally and professionally, and empowering to carve their own path in the company.`}</p>
    <p>{`Sounds like the kind of place you’d like to be? Then we’d love to meet you!`}</p>
    <p>{`Life af Kata.ai Image 1Life af Kata.ai Image 2Life af Kata.ai Image 3
What our team says about working for Kata.ai`}</p>
    <p>{`benefits`}</p>
    <p>{`Working remotely / Work from Home (WFH)`}</p>
    <p>{`Flexibility is really important to us. Hence, we offer working remotely or working from home (WFH) for max 2 days in a week.`}</p>
    <p>{`Flexible work life`}</p>
    <p>{`We trust you to know your schedule and work when you feel most productive.`}</p>
    <p>{`Competitive salary & share options`}</p>
    <p>{`We value our people. We give competitive salary and provide stock options so that when we win, we’ll win together.`}</p>
    <p>{`Free lunch, coffee & foods`}</p>
    <p>{`Great coffee, snacks, and catered lunches make it easy to stay energized at work. Help yourself to treats throughout the day.`}</p>
    <p>{`Health insurance`}</p>
    <p>{`Our team’s health and wellness comes first. That’s why we provide great health insurances for all members of our team.`}</p>
    <p>{`Courses to grow your capabilities and skills`}</p>
    <p>{`Attend talks, conferences, and professional development events that interest you, and benefit your career growth.`}</p>
    <p>{`Time for personal development`}</p>
    <p>{`We believe that quality time outside the office is important to our employees’ satisfaction at work. Hence, we offer free time for their personal development, so they can come back refreshed.`}</p>
    <p>{`Yodee - Product Growth Manager
“Working in an advanced technology company like Kata.ai really opens my eyes to the endless possibility of how technology can help to improve our lives. The culture and mentorship at Kata.ai from the founders help me grow to the direction where I want to be in the next few years.”`}</p>
    <p>{`PreviousNext
Perks & benefits for our team
Our perks and benefits are part of the culture that inspires our team to work better, wherever they are.`}</p>
    <p>{`Working remotely / Work from Home (WFH)`}</p>
    <p>{`Flexibility is really important to us. Hence, we offer working remotely or working from home (WFH) for max 2 days in a week.`}</p>
    <p>{`Open Positions
Are you great product-builder or a passionate hustler? We have all types of opportunities and career paths for you. If you think you want to create remarkable legacy in Southeast Asia’s technology industry, then it’s your chance to be a part of our family.`}</p>
    <p>{`See Jobs`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      